import React from "react"
import { graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import Header from "../componets/Header/Header"
import Footer from "../componets/Footer/Footer"
import Contacteznous from "../componets/Contacteznous/Contacteznous"
import "./globals.css"

export const query = graphql`
  query {
    logo: file(
      absolutePath: {
        regex: "/images/logo_seformer-f59ecb37be2dc067b743d7ee0a113952.webp/"
      }
    ) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => {
  const {
    data: { logo },
  } = props
  return (
    <div className="pageContact">
      <SiteMetadata />
      <Header {...props} logo={logo} />
      <Contacteznous />
      <Footer />
    </div>
  )
}
