import React from 'react';
import { Link } from "gatsby";
import images from '../../assets/images';
import './Contacteznous.css';

function Contacteznous(props) {
  return (
    <div className="Contacteznous py-5 mb-5">
      <div className="headerContact py-4">
        <div className='container container-seformer'>
          <div className="titlepageContact">
            <h2 className="text-center title-section mx-auto my-3">contactez<span>-NOUS</span> <img src={images.imagetitlecontact} alt='Icone contactez-nous'/></h2>
          </div>
        </div>
      </div>
      <div className="contenucontact pt-100 pb-150">
        <div className='container container-seformer'>
        <ul className="d-flex justify-content-center Menucontact">
          <li className="mx-5">
            <Link to="/#/">
              <img className=" hidehover mx-auto" src={images.iconeenvoiemail} alt='icone envoi email'/>
              <img className=" showhover mx-auto" src={images.iconeenvoiemailblanc} alt='icone envoi email blanc'/>
              <h3>Envoyer un mail</h3>
            </Link>
          </li>
          <li className="mx-5">
            <Link to="/#/">
              <img className=" hidehover mx-auto" src={images.iconeounoustrouver} alt='icone ou nous trouver'/>
              <img className=" showhover mx-auto" src={images.iconeenvoiemailblanc} alt='icone envoi email blanc'/>
              <h3>Nous trouver</h3>
            </Link>
          </li>
        </ul>
      </div>
      </div>
      <section className="Formulaireenvoimail">
        <div className='container container-seformer'>
          <div className="formcontent p-5">
            <h3>Nous contacter</h3>
            <form method="post" action="#">
              <label className="col-md-6">
                Prénom
                <input type="text" name="name" id="name" />
              </label>
              <label className="col-md-6">
                Nom
                <input type="email" name="email" id="email" />
              </label>
              <label className="col-md-6">
                Email
                <input type="text" name="subject" id="subject" />
              </label>
              <label className="col-md-6">
                Téléphone
                <input type="text" name="subject" id="subject" />
              </label>
              <label className="col-md-12">
                Sujet
                <select>
                  <option>Choisir un sujet</option>
                </select>
              </label>
              <label className="col-md-12">
                Message
                <textarea name="message" id="message" rows="5" />
              </label>
              <label className="col-md-12">
                <input
                  type="file"

                />
              </label>
              <button type="submit">Valide</button>

            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Contacteznous;